import { createContext } from "react";
import { type ErrorCode } from "../../services/AccountService";

const noOp = () => {};

export interface AuthContextActions {
    onReset: () => void; // Resets authentication errors.
    onSignup: (
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        preferredLanguageCode?: string,
        referrer?: string
    ) => void;
    onLogin: (email: string, password: string) => void;
    onLogout: () => void;
    onGoogleAuthenticationPassed: (
        tokenId: string,
        preferredLanguageCode: string,
        referrer?: string
    ) => void; // - Indicates google authentication succeeded.
    onGoogleAuthenticationFailed: () => void; // - Indicates google authentication failed.
    onRemoveHasRecentlyLoggedIn: () => void; // - Resets the flag that indicates if the user recently logged in.
    onResetExpireSession: () => void; // - Resets the flag that indicates that the user's session should expire.
    onExpireSession: () => void; // - Indicates the user's session should expire.
    onResetSessionExpired: () => void; // - Resets the flag that indicates that the user's session expired.
    onSessionExpired: () => void; // - Expires the user's session.
}

export interface AuthContext {
    isValidating: boolean; // Whether or not the user's authentication status is being validated.
    isAuthenticating: boolean; // Whether or not the authentication is in progress.
    isLoggedIn: boolean; // Whether or not the user is logged in.
    hasError: boolean; // Whether or not there's an authentication error.
    errorCode: ErrorCode | null; // The error code if there's an authentication error.
    hasRecentlyLoggedIn: boolean; // Whether or not the user recently logged in.
    shouldExpireSession: boolean; // Whether or not the user's session should expire.
    isSessionExpired: boolean; // Whether or not the user's session has expired.
    authMachineActions: AuthContextActions; // Functions to communicate with the Auth machine.
}

export const initialContext: AuthContext = {
    isValidating: false,
    isAuthenticating: false,
    isLoggedIn: false,
    hasError: false,
    errorCode: null,
    hasRecentlyLoggedIn: false,
    shouldExpireSession: false,
    isSessionExpired: false,
    authMachineActions: {
        onReset: noOp,
        onSignup: noOp,
        onLogin: noOp,
        onLogout: noOp,
        onGoogleAuthenticationPassed: noOp,
        onGoogleAuthenticationFailed: noOp,
        onRemoveHasRecentlyLoggedIn: noOp,
        onResetExpireSession: noOp,
        onExpireSession: noOp,
        onResetSessionExpired: noOp,
        onSessionExpired: noOp,
    },
};

const AuthContext = createContext(initialContext);

export default AuthContext;
