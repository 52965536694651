import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { trackPromise } from "react-promise-tracker";
import accountService from "../AccountService";
import type AccountServiceError from "../AccountServiceError";
import type { UpdateAccountDetails } from "../types";

interface UpdateNameVariables {
    firstName: string;
    lastName: string;
}

const useUpdateName = () => {
    const { isPending, isSuccess, isError, mutateAsync, reset } = useMutation<
        UpdateAccountDetails,
        AccountServiceError,
        UpdateNameVariables
    >({
        mutationKey: ["updateName"],
        mutationFn: (variables) => {
            const { firstName, lastName } = variables;

            return trackPromise(
                accountService.updateAccountAsync({
                    firstName,
                    lastName,
                })
            );
        },
    });

    const updateName = useCallback(
        (firstName: string, lastName: string) => mutateAsync({ firstName, lastName }),
        [mutateAsync]
    );

    return {
        updateName,
        isLoading: isPending,
        isSuccess,
        isError,
        clearUpdateNameState: reset,
    };
};

export default useUpdateName;
