import is from "@sindresorhus/is";
import { type Timestamp as GrpcTimestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { millisecondsToSeconds, secondsToMilliseconds } from "../../utils/commonUtils";

const fromGrpcTimestamp = (timestamp?: GrpcTimestamp.AsObject) => {
    if (is.undefined(timestamp)) {
        return null;
    }

    return new Date(secondsToMilliseconds(timestamp.seconds)).toISOString();
};

const toGrpcTimestamp = (date?: string | null): GrpcTimestamp.AsObject | null => {
    if (is.nullOrUndefined(date)) {
        return null;
    }

    return {
        seconds: millisecondsToSeconds(new Date(date).getTime()),
        nanos: 0,
    };
};

const toGrpcStringValue = (value: string): StringValue => {
    const result = new StringValue();
    result.setValue(value);

    return result;
};

export { toGrpcStringValue, fromGrpcTimestamp, toGrpcTimestamp };
