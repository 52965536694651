import { useMutation } from "@tanstack/react-query";
import { trackPromise } from "react-promise-tracker";
import accountService from "../AccountService";

interface ResetPasswordVariables {
    token: string;
    password: string;
}

const useResetPassword = () => {
    const { isPending, isSuccess, isError, mutateAsync, reset } = useMutation<
        void,
        Error,
        ResetPasswordVariables
    >({
        mutationKey: ["resetPassword"],
        mutationFn: (variables) => {
            const { token, password } = variables;
            return trackPromise(accountService.resetPasswordAsync(token, password));
        },
    });

    const resetPassword = (token: string, password: string) =>
        mutateAsync({
            token,
            password,
        });

    return {
        resetPassword,
        isLoading: isPending,
        isSuccess,
        isError,
        clearResetPasswordState: reset,
    };
};

export default useResetPassword;
