import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { trackPromise } from "react-promise-tracker";
import AccountService from "../AccountService";
import type AccountServiceError from "../AccountServiceError";
import type { GetAccountResponse } from "../types";

type UseGetAccountQueryOptions = UseQueryOptions<
    GetAccountResponse,
    AccountServiceError,
    GetAccountResponse,
    string[]
>;

const defaultConfig: UseGetAccountQueryOptions = {
    enabled: true,
    refetchInterval: false,
    queryKey: [],
};

interface AccountQueryData {
    isIdle: boolean;
    isFetching: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    account?: GetAccountResponse;
}

const useGetAccount = (config: Partial<UseGetAccountQueryOptions> = {}): AccountQueryData => {
    const { enabled, refetchInterval } = { ...defaultConfig, ...config };

    const {
        data,
        isFetching,
        isPending: isLoading,
        isSuccess,
        isError,
    } = useQuery<GetAccountResponse, AccountServiceError, GetAccountResponse, string[]>({
        queryKey: ["getAccount"],
        queryFn: () => trackPromise(AccountService.getAccountAsync()),
        enabled,
        refetchInterval,
        gcTime: 0,
        staleTime: 0,
        retry: false,
    });

    const isIdle = !enabled && !data && !isLoading && !isError;

    return {
        account: data,
        isIdle,
        isFetching,
        isLoading,
        isSuccess,
        isError,
    };
};

export default useGetAccount;
