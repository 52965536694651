import { useMutation } from "@tanstack/react-query";
import { trackPromise } from "react-promise-tracker";
import accountService from "../AccountService";
import type AccountServiceError from "../AccountServiceError";

const useSendEmailVerification = () => {
    const { isPending, isSuccess, isError, mutateAsync, reset } = useMutation<
        void,
        AccountServiceError
    >({
        mutationKey: ["sendEmailVerification"],
        mutationFn: () => trackPromise(accountService.sendEmailVerificationAsync()),
    });

    const sendEmailVerification = () => mutateAsync();

    return {
        sendEmailVerification,
        isLoading: isPending,
        isSuccess,
        isError,
        clearSendEmailVerificationState: reset,
    };
};

export default useSendEmailVerification;
